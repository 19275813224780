import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import styles from './styles';

const Video = ({ src, id, title }) => {
  const classes = styles();
  return (
    <>
      <Grid container alignItems="center" justify="center" my={5}>
        <Typography className={classes.title} >{title}</Typography>
      </Grid>
      <Grid container justify="center" id={id}>
        <iframe
          src={src}
          title={"title"}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          className={classes.video}
          sandbox
        />
      </Grid>
    </>
  )
};

export default Video

Video.propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired, // Redirects directly on the video from search
  title: PropTypes.string.isRequired
};
