import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  video: {
    width: '90vw',
    height: 'calc(90vw/1.77)',
    margin: '2% auto 5%',
    [theme.breakpoints.up("sm")]: {
      width: '60vw',
      height: 'calc(60vw/1.77)',
    }
  },
  title: {
    margin: '5% 0 0 0',
    fontSize: '1.5rem',
    color: '#C45824',
    fontWeight: 'bold'
  }
}));
