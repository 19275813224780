import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import useStyles from '../pagesStyles/cotationsStyles';
import { Box, Typography } from '@material-ui/core';

import Layout from '../components/layout';
import Hero from '../components/Hero/index';
import CustomButton from '../components/CustomButton/customButton';
import Video from '../components/Video';
import { addProtocol } from '../utils/format'

const CotationsPage = ({ data }) => {

  const classes = useStyles();

  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{frontmatter.SEOtitle}</title>
        <meta name="description" content={frontmatter.SEOdescription} />
      </Helmet>

      <Hero
        image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
        title={frontmatter.title}
        alt={frontmatter.alt}
      />
      <Box className={classes.margin}>

        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase1}
        </Typography>
        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase2first}<span className={classes.bold}>{frontmatter.phrase2second}</span>{frontmatter.phrase2third}
        </Typography>
        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase3}
        </Typography>

        <Box className={classes.frameColor}>
          <Typography paragraph className={classes.frameTitle} component="p">
            {frontmatter.phrase4first}<span className={classes.bold}>{frontmatter.phrase4second}</span>{frontmatter.phrase4third}
          </Typography>
          <Typography paragraph className={classes.frameTitle} component="p">
            {frontmatter.phrase5first}<span className={classes.bold}>{frontmatter.phrase5second}</span>{frontmatter.phrase5third}
          </Typography>
        </Box>

        <Typography className={classes.colorTextSecondary} component="h3">
          {frontmatter.subtitle1}
        </Typography>

        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase6}
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
          {frontmatter.phrase7}
        </Typography>

        <Typography className={classes.colorTextSecondary} component="h3">
          {frontmatter.subtitle2}
        </Typography>

        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase8}
        </Typography>
        <Typography paragraph className={classes.text} variant="body1" component="p">
          {frontmatter.phrase9}
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
          {frontmatter.phrase10}
        </Typography>

        {data.allContentfulAsset.edges.map((edge) => {
          return <Video src={edge.node.file.url} id="video" title={edge.node.title} />
        })}

        <div className={classes.buttonContainer}>

          <CustomButton
            className={classes.button}
            buttonStyle="secondaryButton"
            text="ACCÉDER AUX SERVICES"
            link={`${addProtocol(process.env.GATSBY_ADHERENT_URL, "https")}/cotation`}
            isOutsideLink={true}
          />
        </div>
      </Box>
    </Layout>
  )
}

export default CotationsPage

export const query = graphql`
  query {
    allContentfulAsset(filter: {contentful_id: {eq: "2YRKZ7IgJe3LYmTtPGJJXf"}}) {
        edges {
          node {
            file {
              url
            }
            title
          }
        }
      },
    markdownRemark(fileAbsolutePath: {regex: "/cotations.md/"}) {
        frontmatter {
          SEOtitle
          SEOdescription
          title
          coverImage {
            childImageSharp {
                gatsbyImageData 
                }
              }
          alt
          phrase1
          phrase2first
          phrase2second
          phrase2third
          phrase3
          phrase4first
          phrase4second
          phrase4third
          phrase5first
          phrase5second
          phrase5third
          subtitle1
          phrase6
          phrase7
          subtitle2
          phrase8
          phrase9
          phrase10
        }
      }
  }
`
