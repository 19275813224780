import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
  colorTextSecondary: {
    paddingTop: '2%',
    paddingBottom: '2%',
    fontSize: '1.5rem',
    color: '#C45824',
    fontWeight: 500,
    '@media (max-width:570px)': {
      textAlign: 'center',
      fontSize: '1rem'
    },
  },
  frameColor: {
    backgroundColor: '#C45824',
    marginTop: '2%',
    marginBottom: '2%',
    padding: '2%'
  },
  frameTitle: {
    color: '#fff',
    lineHeight: '1.5',
    fontSize: 'clamp(.8rem, 1.7vw, 24px)',
    textAlign: 'center',
    fontWeight: 500,
    '@media (max-width:570px)': {
      paddingLeft: '1%',
      paddingRight: '1%'
    },
  },
  margin: {
    paddingRight: '5%',
    paddingLeft: '5%',
    paddingTop: '1%'
  },
  text: {
    paddingTop: '0.5%',
    '@media (max-width:570px)': {
      textAlign: 'justify'
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingRight: '30%',
    paddingLeft: '30%',
    '@media (max-width:910px)': {
      padding: 0,
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  button: {
    '@media (max-width:910px)': {
      marginBottom: '3% !important',
      marginTop: '3% !important'

    },
  },
  bold: {
    fontWeight: 700
  }
}));
